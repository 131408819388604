<template>
  <div>
    <div class="nav-top">
      <div
        @click="toSwitch"
        style="margin-left: 4.5%; font-size: 14px; font-weight: 500"
      >
        <van-icon name="arrow-down" /> {{ store.getters.getCustomer }}
      </div>
      <div style="margin-right: 45%">运单</div>
    </div>
    <div>
      <van-search
        v-model="searchForm.waybillNo"
        clearable
        @clear="onSearch"
        @search="onSearch"
        placeholder="请输入运单号搜索"
        show-action
      >
        <template #action>
          <div class="search-btn" @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>
    <div style="margin-top: -24rpx">
      <van-tabs
        line-width="10px"
        v-model:active="activeType"
        @change="onSearch"
      >
        <!-- <van-tab title="未收货" :name="5"></van-tab> -->
        <van-tab title="未入库" :name="4"></van-tab>
        <van-tab title="已入库" :name="3"></van-tab>
        <van-tab title="已入仓" :name="2"></van-tab>
        <van-tab title="退货" :name="7"></van-tab>
        <van-tab title="全部" :name="0"></van-tab>
      </van-tabs>
      <div style="background: rgb(242, 243, 249); padding: 1px">
        <van-pull-refresh
          v-model="pullRefreshOpt.refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model:loading="pullRefreshOpt.loading"
            :finished="pullRefreshOpt.finished"
            :finished-text="pullRefreshOpt.finishedText"
            v-model:error="pullRefreshOpt.error"
            error-text="请求失败，点击重新加载"
            @load="onLoad"
            :offset="100"
          >
            <div
              class="order_box"
              v-for="(item, index) in tableData"
              :key="index"
            >
              <div style="padding: 5px 0">
                <div class="order_content" @click="toDetail(item.id)">
                  <div style="display: flex">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ item.waybill_no }}
                    </div>
                    <div class="fba-tag" v-if="item.type == 1">FBA</div>
                    <div class="fba-tag-blue" v-if="item.type == 2">非FBA</div>
                  </div>
                  <div style="color: #ffbf1b">{{ item.status_name }}</div>
                </div>
                <div class="order_content" @click="toDetail(item.id)">
                  <div style="color: #999999">
                    <template v-if="activeType === 4 || activeType === 3">
                      下单时间：
                      <span v-if="item.created_at">{{
                        new Date(item.created_at * 1000).toLocaleString()
                      }}</span>
                      <span v-else> - </span>
                    </template>
                    <template v-else>
                      入仓时间：
                      <span v-if="item.irikura_time">
                        {{
                          new Date(item.irikura_time * 1000).toLocaleString()
                        }}
                      </span>
                      <span v-else> - </span>
                    </template>
                  </div>
                  <div>{{ item.customer_code }}</div>
                </div>
                <div class="order_content" v-if="activeType === 4">
                  <div style="color: #999999">
                    货好时间：
                    <span v-if="item.good_time">{{
                      new Date(item.good_time * 1000).toLocaleString()
                    }}</span>
                    <span v-else> - </span>
                  </div>
                </div>
                <div class="order_content" @click="toDetail(item.id)">
                  <div style="font-size: 18px; font-weight: 500">
                    {{ item.country_name }}
                  </div>
                  <div style="font-size: 14px; color: #ff5f2d; margin-top: 3px">
                    {{ item.agent_channel_name }}
                  </div>
                </div>
                <div class="order_content" @click="toDetail(item.id)">
                  <div style="color: #999999">目的地</div>
                  <div style="color: #999999">{{ item.cargoes_num }}件</div>
                </div>
                <div>
                  <van-cell
                    custom-class="cell-background"
                    :title="item.milestone_name_zh"
                    @click="
                      onShipDetail(
                        item.id,
                        item.transship_code,
                        item.transship_no
                      )
                    "
                    is-link
                  ></van-cell>
                </div>
              </div>
            </div>
          </van-list>
          <emptyData
            v-if="!pullRefreshOpt.loading && !tableData.length"
          ></emptyData>
        </van-pull-refresh>
      </div>
    </div>
    <van-popup
      v-model:show="popShow"
      round
      closeable
      position="bottom"
      custom-style="height: 50%"
    >
      <div class="popup-content">
        <div style="font-size: 18px">物流详情</div>
        <div style="margin: 24px 0">
          <van-tag color="#FFF3EF" text-color="#FF5F2D">{{
            billStatu.transship_code
          }}</van-tag>
          {{ billStatu.transship_no }}
        </div>
        <div style="height: 350px; overflow: scroll">
          <van-steps active="0" active-color="#FF5F2D" direction="vertical">
            <van-step v-for="(item, index) in billWay" :key="index">
              <p>{{ item.name_zh }}</p>
              <p>{{ new Date(item.node_time * 1000).toLocaleString() }}</p>
            </van-step>
          </van-steps>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { wxAuthor } from "@/api/wxAuthor";
import emptyData from "@/components/emptyData/index.vue";
import InitHook from "./composables/billHtml/initHook";
import SearchMethodsHook from "./composables/billHtml/searchMethodsHook";
export default defineComponent({
  components: {
    emptyData,
  },
  setup() {
    const Route = useRoute();
    const {
      searchForm,
      pageConfig,
      tableData,
      nextPageData,
      onRefresh,
      onLoad,
      pullRefreshOpt,
      store,
      activeType,
    } = InitHook();
    const {
      onSearch,
      billWay,
      billStatu,
      onShipDetail,
      toDetail,
      toSwitch,
      popShow,
    } = SearchMethodsHook({
      pullRefreshOpt,
      pageConfig,
      tableData,
      onLoad,
    });
    onMounted(() => {
      // 是否带有code
      const authorCode = Route.query.code;
      if (authorCode) {
        wxAuthor({ code: authorCode })
          .then((res) => {
            console.log("企业微信绑定成功", res);
          })
          .catch((err) => {
            console.log("企业微信绑定失败", err);
          });
      }
    });
    return {
      onSearch,
      popShow,
      onShipDetail,
      toDetail,
      billWay,
      billStatu,
      toSwitch,
      store,
      searchForm,
      pageConfig,
      tableData,
      nextPageData,
      onRefresh,
      onLoad,
      pullRefreshOpt,
      activeType,
    };
  },
});
</script>

<style scoped>
.nav-top {
  width: 100%;
  height: 70px;
  line-height: 80px;
  text-align: center;
  font-weight: bold;
  background: linear-gradient(180deg, #e4e8fa 0%, rgba(255, 255, 255, 0) 74.81%),
    #ffffff;
  display: flex;
  justify-content: space-between;
}
.search-btn {
  width: 56px;
  height: 24px;
  text-align: center;
  background: linear-gradient(112.28deg, #ff8863 18.3%, #fa154c 83.28%);
  border-radius: 14px;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  color: #ffffff;
}
.order_box {
  width: calc(100% - 24px);
  background: white;
  border-radius: 12px;
  margin: 12px 0 12px 12px;
  font-size: 12px;
}
.order_content {
  display: flex;
  justify-content: space-between;
  margin: 10px 14px;
}
.fba-tag {
  width: 36px;
  background-image: linear-gradient(to right, #ffdba5, #ff4d77);
  color: white;
  border-radius: 19px;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  height: 18px;
  float: right;
  margin-left: 3px;
}
.fba-tag-blue {
  width: 42px;
  background-image: linear-gradient(to right, #39f9cb, #747afe);
  color: white;
  border-radius: 19px;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  height: 18px;
  float: right;
  margin-left: 3px;
}
.cell-background {
  background: #fafafa;
}
.popup-content {
  margin: 16px 18px 16px 18px;
}
:deep(.van-pull-refresh__track) {
  height: calc(100vh - 216px);
  overflow: auto;
}
</style>
