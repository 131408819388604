import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { getWaybillTrackInfo } from "@/api/Bill/index";
export default function SearchMethodsHook(state) {
  const { pageConfig, tableData, pullRefreshOpt, onLoad } = state;
  const Router = useRouter();
  const popShow = ref(false);
  const onSearch = function () {
    pageConfig.page = 1;
    pageConfig.total = 0;
    tableData.value = [];
    pullRefreshOpt.loading = true;
    pullRefreshOpt.finished = false;
    onLoad();
  };
  const billWay = ref([]); // 运单轨迹
  const billStatu = reactive({
    //物流状态
    transship_no: "1",
    transship_code: "1",
  });
  const onShipDetail = function (id, transCode, transNo) {
    getWaybillTrackInfo({ waybillId: id }).then((res) => {
      billWay.value = res.data.trails;
      billStatu.transship_no = transNo;
      billStatu.transship_code = transCode;
    });
    popShow.value = true;
  };
  const toDetail = function (id) {
    Router.push({ path: "/billDetail", query: { id: id } });
  };
  const toSwitch = function () {
    Router.push({ path: "/customSwitch" });
  };
  return {
    popShow,
    onSearch,
    billWay,
    billStatu,
    onShipDetail,
    toDetail,
    toSwitch,
  };
}
