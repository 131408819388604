import PullRefreshListHook from "@/hook/pullRefreshListHook";
import { getPreorderList } from "@/api/preorder/preorderHtml";
import { useStore } from "vuex";
import { reactive } from "vue";
export default function InitHook() {
  const store = useStore();
  const searchForm = reactive({
    forecastNo: "",
    status: 1,
  });
  const apiRequest = () => {
    const params = {
      customerCode:
        store.getters.getCustomer == "全部客户"
          ? ""
          : store.getters.getCustomer,
      ...searchForm,
      ...pageConfig,
    };
    return getPreorderList(params);
  };
  const {
    pullRefreshOpt,
    onLoad,
    onRefresh,
    nextPageData,
    tableData,
    pageConfig,
  } = PullRefreshListHook(apiRequest);
  return {
    searchForm,
    pageConfig,
    tableData,
    nextPageData,
    onRefresh,
    onLoad,
    pullRefreshOpt,
    store,
  };
}
