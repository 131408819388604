<template>
  <div>
    <div class="nav-top">
      <div
        @click="toSwitch"
        style="margin-left: 4.5%; font-size: 14px; font-weight: 500"
      >
        <van-icon name="arrow-down" /> {{ store.getters.getCustomer }}
      </div>
      <div style="margin-right: 42.5%">预报单</div>
    </div>
    <div>
      <van-search
        clearable
        v-model="searchForm.forecastNo"
        @clear="onSearch"
        @search="onSearch"
        placeholder="请输入运单号搜索"
        show-action
      >
        <template #action>
          <div class="search-btn" @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>
    <div style="margin-top: -24rpx">
      <van-tabs
        line-width="10px"
        v-model:active="searchForm.status"
        @change="onSearch"
      >
        <van-tab title="已下单" :name="1"></van-tab>
        <van-tab title="已收货" :name="2"></van-tab>
        <van-tab title="已入库" :name="3"></van-tab>
        <van-tab title="已取消" :name="99"></van-tab>
        <van-tab title="全部" :name="0"></van-tab>
      </van-tabs>
      <div style="background: rgb(242, 243, 249); padding: 1px">
        <van-pull-refresh
          v-model="pullRefreshOpt.refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model:loading="pullRefreshOpt.loading"
            :finished="pullRefreshOpt.finished"
            :finished-text="pullRefreshOpt.finishedText"
            v-model:error="pullRefreshOpt.error"
            error-text="请求失败，点击重新加载"
            @load="onLoad"
            :offset="100"
          >
            <div
              class="order_box"
              v-for="(item, index) in tableData"
              :key="index"
              @click="toBill(item.id)"
            >
              <div style="padding: 5px 0px">
                <div class="order_content">
                  <div style="display: flex">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ item.forecast_no }}
                    </div>
                    <div class="fba-tag" v-if="item.type == 0">无计划</div>
                    <div class="fba-tag-blue" v-if="item.type == 1">有计划</div>
                  </div>
                  <div
                    style="color: #ffbf1b"
                    v-if="
                      item.status == 1 &&
                      item.finish_status !== 2 &&
                      ![99].includes(searchForm.status)
                    "
                  >
                    已下单
                  </div>
                  <div
                    style="color: #ffbf1b"
                    v-if="
                      item.status == 2 &&
                      item.finish_status !== 2 &&
                      ![99].includes(searchForm.status)
                    "
                  >
                    已收货
                  </div>
                  <div
                    style="color: #ffbf1b"
                    v-if="
                      item.status == 3 &&
                      item.finish_status !== 2 &&
                      ![99].includes(searchForm.status)
                    "
                  >
                    已入库
                  </div>
                  <div style="color: #ffbf1b" v-if="item.finish_status == 2">
                    已取消
                  </div>
                </div>
                <div class="order_content">
                  <div style="color: #999999">
                    下单时间：{{
                      new Date(item.created_at * 1000).toLocaleString()
                    }}
                  </div>
                  <div>{{ item.customer_code }}</div>
                </div>
                <div class="order_content">
                  <div style="font-size: 24px; font-family: MiSans">
                    {{ item.waybill_count
                    }}<span style="font-size: 14px"> 票 </span>
                    {{ item.box_count
                    }}<span style="font-size: 14px"> 件 </span>
                    <van-tag
                      color="#FFEDD8"
                      text-color="#FF5F2D"
                      v-if="item.box_count - item.use_box_count != 0"
                      >{{ item.box_count - item.use_box_count }}件未发</van-tag
                    >
                  </div>
                  <div style="font-size: 18px">
                    <van-icon name="phone-o" color="#FFBF1B" size="28px" />
                    {{ item.salesman_name }}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
          <emptyData
            v-if="!pullRefreshOpt.loading && !tableData.length"
          ></emptyData>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import InitHook from "./composables/preorderHtml/initHook";
import SearchMethodsHook from "./composables/preorderHtml/searchMethodsHook";
import emptyData from "@/components/emptyData/index.vue";
export default defineComponent({
  components: {
    emptyData,
  },
  setup() {
    const {
      searchForm,
      pageConfig,
      tableData,
      nextPageData,
      onRefresh,
      onLoad,
      pullRefreshOpt,
      store,
    } = InitHook();
    const { toSwitch, toBill, onSearch } = SearchMethodsHook({
      pageConfig,
      tableData,
      pullRefreshOpt,
      onLoad,
    });
    return {
      onSearch,
      toSwitch,
      toBill,
      store,
      searchForm,
      pageConfig,
      tableData,
      nextPageData,
      onRefresh,
      onLoad,
      pullRefreshOpt,
    };
  },
});
</script>

<style scoped>
.nav-top {
  width: 100%;
  height: 70px;
  line-height: 80px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, #e4e8fa 0%, rgba(255, 255, 255, 0) 74.81%),
    #ffffff;
}
.search-btn {
  width: 56px;
  height: 24px;
  text-align: center;
  background: linear-gradient(112.28deg, #ff8863 18.3%, #fa154c 83.28%);
  border-radius: 14px;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  color: #ffffff;
}
.order_box {
  width: calc(100% - 24px);
  height: 100%;
  background: white;
  border-radius: 12px;
  margin: 12px 0 12px 12px;
  font-size: 12px;
}
.order_content {
  display: flex;
  justify-content: space-between;
  margin: 16px 14px;
}
.fba-tag {
  width: 48px;
  height: 18px;
  background-image: linear-gradient(to right, #ffdba5, #ff4d77);
  color: white;
  border-radius: 19px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  line-height: 18px;
  float: right;
  margin-left: 3px;
  margin-top: 1px;
}
.fba-tag-blue {
  width: 42px;
  background-image: linear-gradient(to right, #39f9cb, #747afe);
  color: white;
  border-radius: 19px;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  height: 18px;
  float: right;
  margin-left: 3px;
}
.cell-background {
  background: #fafafa;
}
.popup-content {
  margin: 16px 18px 16px 18px;
}
:deep(.van-pull-refresh__track) {
  height: calc(100vh - 216px);
  overflow: auto;
}
</style>
