// 1. 引入你需要的组件
import {
  Tabbar,
  TabbarItem,
  Button,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Step,
  Steps,
  Search,
  Picker,
  Lazyload,
  Tag,
  Icon,
  Popup,
  Image as VanImage,
  Toast,
  Dialog,
  Field,
  List,
  PullRefresh,
  ActionSheet,
} from "vant";
export default (app) => {
  app.use(Cell);
  app.use(CellGroup);
  app.use(Button);
  app.use(Tabbar);
  app.use(TabbarItem);
  app.use(Tab);
  app.use(Tabs);
  app.use(Search);
  app.use(Picker);
  app.use(Lazyload);
  app.use(VanImage);
  app.use(Toast);
  app.use(Step);
  app.use(Steps);
  app.use(Tag);
  app.use(Icon);
  app.use(Popup);
  app.use(Dialog);
  app.use(Field);
  app.use(List);
  app.use(PullRefresh);
  app.use(ActionSheet);
};
