import axios from "axios";
import store from "@/store";
import Router from "@/router/index";
import { Notify } from "vant";
function filterRequestData(obj) {
  let o = {};
  for (let key in obj) {
    let b1 = obj[key] === 0;
    let b2 = obj[key] === false;
    if (obj[key] || b1 || b2) {
      o[key] = obj[key];
    }
  }
  return o;
}

//创建axios的实例
const httpService = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 5000,
});

//axios的拦截--request
httpService.interceptors.request.use(
  (config) => {
    const rqParams = filterRequestData(config.params);
    const rqData = filterRequestData(config.data);
    config.headers["Content-Type"] = "application/json";
    config.headers["device-type"] = "web";
    config.headers["ansuex-manage-token"] = store.getters.getToken;
    if (config.method === "post") {
      config.data = rqData;
    } else {
      config.data = rqData;
      config.params = rqParams;
    }
    return config;
  },
  (err) => {
    Promise.reject(err); // 请求错误处理
  }
);

//4、axios的拦截--response
httpService.interceptors.response.use(
  (response) => {
    // TODO:具体的code对应的处理可继续添加修改
    const { data } = response;
    if (data.code === 100000) {
      Notify({ type: "danger", message: "身份信息过期、请重新登录!" });
      setTimeout(() => {
        Router.replace({
          path: "/login",
          query: {
            redirect: Router.currentRoute.fullPath,
          },
        });
      }, 1000);
    } else {
      return data;
    }
    return data;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default httpService;
