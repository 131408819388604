import PullRefreshListHook from "@/hook/pullRefreshListHook";
import {
  getAllWayBillList,
  getIrikuraList,
  getWarehousedWayBillList,
} from "@/api/Bill/index";
import { useStore } from "vuex";
import { reactive, ref } from "vue";
export default function InitHook() {
  const store = useStore();
  const activeType = ref(0); // 默认未收货
  const searchForm = reactive({
    waybillNo: "",
  });
  const apiRequest = () => {
    const params = {
      customerCode:
        store.getters.getCustomer == "全部客户"
          ? ""
          : store.getters.getCustomer,
      ...searchForm,
      ...pageConfig,
    };
    switch (activeType.value) {
      case 0: // 获取全部订单
        return getAllWayBillList(params);
      case 7: // 退货
        params.status = activeType.value;
        return getAllWayBillList(params);
      case 2: // 已入仓
        return getIrikuraList(params);
      case 3: // 已入库
        params.status = 2;
        return getWarehousedWayBillList(params);
      case 4: // 未入库
        params.status = 1;
        return getWarehousedWayBillList(params);
      case 5: // 未收货
        return Promise.resolve({});
    }
  };

  const {
    pullRefreshOpt,
    onLoad,
    onRefresh,
    nextPageData,
    tableData,
    pageConfig,
  } = PullRefreshListHook(apiRequest);
  return {
    searchForm,
    pageConfig,
    tableData,
    nextPageData,
    onRefresh,
    onLoad,
    pullRefreshOpt,
    store,
    activeType,
  };
}
