<template>
  <div class="content-outter">
    <div class="nav-top">我的</div>
    <div class="profile">
      <div class="profile-name">{{ store.getters.getName }}</div>
    </div>
    <div class="content">
      <van-cell-group inset>
        <van-cell
          class="content-tab"
          @click="toSwitch"
          center
          title="我的客户"
          size="large"
          is-link
        />
      </van-cell-group>
      <van-cell-group inset style="margin-top: 12px">
        <van-cell
          class="content-tab"
          @click="dialogShow = true"
          center
          title="修改账号密码"
          size="large"
          is-link
        />
      </van-cell-group>
      <van-cell-group inset style="margin-top: 12px">
        <van-cell
          class="content-tab"
          @click="accountBindHandle"
          center
          title="账号绑定"
          size="large"
          is-link
        />
      </van-cell-group>
    </div>
    <div class="m-footer">
      <div class="m-f-btn" @click="logout">退出登录</div>
    </div>
    <van-dialog
      v-model:show="dialogShow"
      @cancel="dialogShow = false"
      @confirm="pwdonChange"
      title="修改密码"
      show-cancel-button
    >
      <div class="change-password">
        <van-cell-group inset>
          <van-field
            v-model="pwdForm.oldPassword"
            type="password"
            label="旧密码"
            placeholder="请输入"
          />
          <van-field
            v-model="pwdForm.password"
            type="password"
            label="新密码"
            placeholder="请输入"
          />
          <van-field
            v-model="pwdForm.confirmPassword"
            type="password"
            label="确认密码"
            placeholder="请输入"
          />
        </van-cell-group>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Dialog } from "vant";
export default defineComponent({
  setup() {
    const Router = useRouter();
    const store = useStore();
    const profileName = ref("");
    const { proxy } = getCurrentInstance();
    const dialogShow = ref(false);
    const pwdForm = reactive({
      oldPassword: "",
      password: "",
      confirmPassword: "",
    });
    const logout = function () {
      Dialog.confirm({
        title: "退出",
        message: "是否退出登录？",
      })
        .then(() => {
          store.commit("setToken", null);
          Router.push({
            path: "/login",
          });
        })
        .catch(() => {});
    };
    const pwdonChange = function () {
      proxy.$post("/user/repass", pwdForm).then((res) => {
        if (res.code == 0) {
          Notify({ type: "success", message: res.msg + "，请重新登录。" });
          store.commit("setToken", null);
          Router.push({
            path: "/login",
          });
        } else {
          Notify({ type: "danger", message: res.msg });
          pwdForm.oldPassword = "";
          pwdForm.password = "";
          confirmPassword = "";
        }
      });
    };
    const toSwitch = function () {
      Router.push({ path: "/customSwitch" });
    };
    // 账号绑定
    const accountBindHandle = () => {
      Router.push({
        path: "/accountBind",
      });
    };
    return {
      profileName,
      logout,
      store,
      dialogShow,
      pwdForm,
      pwdonChange,
      toSwitch,
      accountBindHandle,
    };
  },
});
</script>

<style scoped>
.nav-top {
  width: 100%;
  height: 70px;
  line-height: 80px;
  text-align: center;
  font-weight: bold;
}
.content-outter {
  background: linear-gradient(180deg, #e4e8fa 0%, rgba(255, 255, 255, 0) 74.81%),
    #ffffff;
  height: 330px;
}
.profile {
  margin: 24px;
}
.profile-name {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #333333;
}

.content {
  background: linear-gradient(
    180deg,
    rgb(245, 246, 250) 0%,
    rgb(255, 255, 255) 150%
  );
  height: 606px;
}
.content-tab {
  height: 64px;
  background: #ffffff;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
}
.m-footer {
  position: fixed;
  width: 100%;
  height: 50px;
  left: 0px;
  bottom: 120px;
  display: flex;
  justify-content: center;
}
.m-f-btn {
  width: 80%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #fa3564;
  border-radius: 25px;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  font-size: 16px;
  color: #fa3564;
  letter-spacing: 6px;
}
.change-password {
  padding: 12px 0 12px 0;
}
</style>
