<template>
  <div class="empty-data-page">
    <img :src="emptyDataImg" alt="暂无数据" />
    <slot name="text">
      <div class="tip-text">{{ tipText }}</div>
    </slot>
  </div>
</template>
<script setup>
import { toRefs } from "vue";
import emptyDataImg from "@/assets/no-data.png";
const props = defineProps({
  tipText: {
    type: String,
    default: () => "这里什么都没有",
  },
});
const { tipText } = toRefs(props);
</script>
<style scoped>
.empty-data-page {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.tip-text {
  font-family: "Asul";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #aeb4cd;
}
</style>
