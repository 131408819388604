export const dataFieldMap = {
  amount: "运费",
  bill_weight: "结算重",
  real_weight: "实重(KG)",
  waybill_num: "运单数",
  volume: "方数",
  volume_weight: "材积",
  customer_num: "发货客户",
  cargoes_num: "件数",
};

export const yesterdayData = [
  {
    label: "材积",
    value: 32.4,
  },
  {
    label: "方数",
    value: 3.3,
  },
  {
    label: "实重(KG)",
    value: 234,
  },
  {
    label: "结算重",
    value: 234,
  },
  {
    label: "件数",
    value: 32.4,
  },
  {
    label: "运单数",
    value: 234,
  },
  {
    label: "发货客户",
    value: 234,
  },
  {
    label: "运费",
    value: 3.3,
  },
];
export const todayData = [
  {
    label: "材积",
    value: 32.4,
  },
  {
    label: "方数",
    value: 3.3,
  },
  {
    label: "实重(KG)",
    value: 234,
  },
  {
    label: "结算重",
    value: 234,
  },
  {
    label: "件数",
    value: 32.4,
  },
  {
    label: "运单数",
    value: 234,
  },
  {
    label: "发货客户",
    value: 234,
  },
  {
    label: "运费",
    value: 3.3,
  },
];
