export default {
    getToken(state) {
        return state.token
    },
    getCustomer(state) {
        let customer = decodeURIComponent(state.customer)
        return customer
    },
    getName(state) {
        let name = decodeURIComponent(state.name)
        return name
    },
}