import Cookies from 'js-cookie';

export const TOKEN_KEY = 'ansuex-user-token';

// 默认保存3天
export const setToken = (token, cookieExpires) => {
    Cookies.set(TOKEN_KEY, token, {
        expires: cookieExpires || 3
    })
}

// 获取token
export const getToken = () => {
    const token = Cookies.get(TOKEN_KEY)
    if (token) return token
    else return false
}

// 删除token
export const delToken = () => {
    Cookies.remove(TOKEN_KEY)
}