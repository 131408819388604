<template>
    <router-view style="margin-bottom: 40px"></router-view>
    <van-tabbar route active-color="#DA1F39">
        <van-tabbar-item replace to="/bill">
            <template #icon="props">
                <img :src="props.active ? bill.active : bill.normal" style="width: 30px; height: 30px" />
            </template>
            <span>订单</span>
        </van-tabbar-item>
        <van-tabbar-item replace to="/preorder">
            <template #icon="props">
                <img :src="props.active ? preorder.active : preorder.normal" style="width: 30px; height: 30px" />
            </template>
            <span>预报</span>
        </van-tabbar-item>
        <van-tabbar-item replace to="/total">
            <template #icon="props">
                <img :src="props.active ? total.active : total.normal" style="width: 30px; height: 30px" />
            </template>
            <span>统计</span>
        </van-tabbar-item>
        <van-tabbar-item replace to="/me">
            <template #icon="props">
                <img :src="props.active ? main.active : main.normal" style="width: 30px; height: 30px" />
            </template>
            <span>我的</span>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
    setup() {
        const active = ref(0);
        const bill = {
            normal: require("./icons/bill.png"),
            active: require("./icons/billActive.png"),
        };
        const preorder = {
            normal: require("./icons/order.png"),
            active: require("./icons/orderActive.png"),
        };
        const total = {
            normal: require("./icons/price.png"),
            active: require("./icons/priceActive.png"),
        };
        const main = {
            normal: require("./icons/main.png"),
            active: require("./icons/mainActive.png"),
        };
        return {
            active,
            bill,
            preorder,
            total,
            main,
        };
    },
});
</script>

<style lang="less" scoped>

</style>