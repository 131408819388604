import httpService from "@/request";
// 企业微信身份授权
export function wxAuthor(params) {
  return httpService({
    url: `/login/qywx/bind`,
    method: "post",
    data: params,
  });
}
// 企业微信身份解绑
export function wxUnAuthor(params) {
  return httpService({
    url: `/login/qywx/unBind`,
    method: "post",
    data: params,
  });
}
