import { createRouter, createWebHistory } from "vue-router";
import LayOut from "@/components/LayOut/LayOut.vue";
import Bill from "@/views/Bill/BillHtml.vue";
import Preorder from "@/views/Preorder/PreorderHtml.vue";
import Total from "@/views/Total/TotalHtml.vue";
import Me from "@/views/Me/MeHtml.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: LayOut,
    redirect: "/bill",
    children: [
      {
        path: "/bill",
        name: "Bill",
        component: Bill,
      },
      {
        path: "/preorder",
        name: "Preorder",
        component: Preorder,
      },
      {
        path: "/total",
        name: "Total",
        component: Total,
      },
      {
        path: "/me",
        name: "Me",
        component: Me,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/billDetail",
    name: "BillDetail",
    component: () => import("../views/Bill/BillDetail.vue"),
  },
  {
    path: "/preorderBill",
    name: "PreorderBill",
    component: () => import("../views/Preorder/PreorderBill.vue"),
  },
  {
    path: "/customSwitch",
    name: "CustomSwitch",
    component: () => import("../views/Preorder/CustomSwitch.vue"),
  },
  {
    path: "/accountBind",
    name: "AccountBind",
    component: () => import("../views/Me/accountBind.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
