<template>
  <div class="total-page">
    <div class="nav-top">统计</div>
    <div class="content-wrapper">
      <div class="has-data" v-if="hasData">
        <div class="yesterday-content">
          <div class="total-title">
            <div class="title-icon">
              <img :src="dataBase" alt="#" />
            </div>
            <span>昨日统计</span>
          </div>
          <div class="total-content">
            <div
              v-for="(attrs, index) in Object.keys(formData.yesterdayData)"
              :key="index"
              class="total-card"
            >
              <div
                :class="[
                  'total-card-content',
                  (index + 1) % 3 !== 0 ? 'card-border' : '',
                ]"
              >
                <div class="total-number">
                  {{ formData.yesterdayData[attrs] }}
                </div>
                <div class="total-number-title">{{ dataFieldMap[attrs] }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="today-content">
          <div class="total-title">
            <div class="title-icon-today">
              <img :src="dataBase" alt="#" />
            </div>
            <span>今日统计</span>
          </div>
          <div class="total-content">
            <div
              v-for="(attrs, index) in Object.keys(formData.todayData)"
              :key="index"
              class="total-card"
            >
              <div
                :class="[
                  'total-card-content',
                  (index + 1) % 3 !== 0 ? 'card-border' : '',
                ]"
              >
                <div class="total-number">{{ formData.todayData[attrs] }}</div>
                <div class="total-number-title">{{ dataFieldMap[attrs] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <empty-data v-else></empty-data>
    </div>
  </div>
</template>

<script>
export default {
  name: "Homediv",
};
</script>
<script setup>
import dataBase from "@/components/LayOut/icons/database.png";
import InitHook from "./composables/initHook";
import emptyData from "@/components/emptyData/index.vue";
import { dataFieldMap } from "./composables/data";
const { formData, hasData } = InitHook();
</script>

<style scoped>
.total-page {
  /* height: calc(100vh - 40px); */
  background: #f6f6fa;
}
.content-wrapper {
  position: relative;
  width: 100%;
  /* height: calc(100% - 70px); */
  height: calc(100vh - 130px);
  overflow: auto;
  padding-bottom: 12px;
}
.content-wrapper > .empty-data-page {
  top: 30%;
}
.nav-top {
  width: 100%;
  height: 70px;
  line-height: 80px;
  text-align: center;
  font-weight: bold;
  background: linear-gradient(180deg, #e4e8fa 0%, rgba(255, 255, 255, 0) 74.81%),
    #ffffff;
}
.yesterday-content {
  background: linear-gradient(
    205.7deg,
    #f1fff8 12.47%,
    rgba(255, 255, 255, 0.2) 38.56%,
    #ffffff 73.41%
  );
  border-radius: 8px;
  height: 270px;
  width: 90%;
  margin-left: 5%;
  margin-top: 12px;
  padding: 11px 0 11px 0;
}
.today-content {
  background: linear-gradient(
    to right bottom,
    #fff1f188 33.51%,
    rgba(253, 247, 228, 0.6) 48.21%,
    #ffffff 88.02%
  );
  border-radius: 8px;
  height: 270px;
  width: 90%;
  margin-left: 5%;
  margin-top: 12px;
  padding: 11px 0 11px 0;
}
.total-title {
  margin: 0 11px;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 12px;
}
.title-icon {
  background: url("@/components/LayOut/icons/rectangleBlue.png");
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 21px;
  margin-right: 10px;
}
.title-icon-today {
  background: url("@/components/LayOut/icons/rectangleRed.png");
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 21px;
  margin-right: 10px;
}
.total-content {
  display: flex;
  flex-wrap: wrap;
}
.total-card {
  width: 33%;
  height: 85px;
  text-align: center;
}
.total-card-content {
  text-align: center;
}
.card-border {
  border-right: 1px solid #ebebeb;
}
.total-number {
  /* margin-top: 20%; */
  font-family: "MiSans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #333;
}
.total-number-title {
  /* font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 13px; */
  color: #666666;
  font-family: "Asul";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
</style>
