import { useRouter } from "vue-router";
export default function SearchMethodsHook(state) {
  const { pageConfig, tableData, pullRefreshOpt, onLoad } = state;
  const Router = useRouter();
  const toSwitch = function () {
    Router.push({ path: "/customSwitch" });
  };
  const toBill = function (id) {
    Router.push({ path: "/preorderBill", query: { id: id } });
  };
  const onSearch = () => {
    pageConfig.page = 1;
    pageConfig.total = 0;
    tableData.value = [];
    pullRefreshOpt.loading = true;
    pullRefreshOpt.finished = false;
    onLoad();
  };
  return {
    toSwitch,
    toBill,
    onSearch,
  };
}
