import { createApp } from 'vue'
import App from './App.vue'
import router from "@/permission"
import store from "@/store/index"
// import 'amfe-flexible'

//vant
import 'vant/lib/index.css';
import vantCompent from "./vantCompent";
import { getReq, postJson, download } from "@/api/index";

const app = createApp(App);
app.config.globalProperties.$get = getReq;
app.config.globalProperties.$post = postJson;
app.config.globalProperties.$download = download;
app.use(router);
app.use(store);
vantCompent(app);
app.mount('#app');
