import httpService from "@/request";

// 全部订单、退货订单
export const getAllWayBillList = (data = {}) => {
  return httpService({
    url: "/order/waybill/waybill/lists",
    method: "post",
    data: data,
  });
};
// 已入仓
export const getIrikuraList = (data = {}) => {
  return httpService({
    url: "/order/waybill/irikura/lists",
    method: "post",
    data: data,
  });
};
// 已入库、未入库
export const getWarehousedWayBillList = (data = {}) => {
  return httpService({
    url: "/order/waybill/lists",
    method: "post",
    data: data,
  });
};
// 获取订单物流信息
export const getWaybillTrackInfo = (data = {}) => {
  return httpService({
    url: "/order/waybill/waybill/trail",
    method: "post",
    data: data,
  });
};
