export default {
    setToken: (state, log) => {
        state.token = log;
        sessionStorage.setItem('token', log);
    },
    setCustomer: (state, log) => {
        let logs = encodeURIComponent(log)
        state.customer = logs;
        sessionStorage.setItem('customer', logs);
    },
    setName: (state, log) => {
        let logs = encodeURIComponent(log)
        state.name = logs;
        sessionStorage.setItem('name', logs);
    },
}