import { computed, onMounted, reactive } from "vue";
import { getTotalInfo } from "@/api/total";
export default function InitHook() {
  const formData = reactive({
    yesterdayData: {},
    todayData: {},
  });
  const hasData = computed(() => {
    return (
      Object.keys(formData.yesterdayData).length &&
      Object.keys(formData.todayData).length
    );
  });
  onMounted(() => {
    getTotalInfo()
      .then((res) => {
        const { data } = res;
        formData.yesterdayData = data[0] || {};
        formData.todayData = data[1] || {};
      })
      .catch((err) => {
        console.log("获取每日数据统计失败", err);
      });
  });
  return {
    formData,
    hasData,
  };
}
