import { reactive, ref } from "vue";
export default function PullRefreshListHook(apiRequest) {
  const tableData = ref([]);
  const pageConfig = reactive({
    page: 1,
    limit: 10,
    total: 0,
  });
  const pullRefreshOpt = reactive({
    refreshing: false, // 下拉刷新
    loading: false, // 列表loading
    finished: false, // 是否最后一页
    error: false, // 加载错误
    finishedText: "没有更多了", // 加载完成（最后一页）提示文案
  });
  const onRefresh = () => {
    // 清空列表数据
    pageConfig.page = 1;
    pageConfig.total = 0;
    pullRefreshOpt.finished = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    pullRefreshOpt.loading = true;
    onLoad();
  };
  const nextPageData = function () {
    return apiRequest()
      .then((res) => {
        const { list = [], total = 0 } = res.data || {};
        tableData.value = tableData.value.concat(list);
        pageConfig.total = total;
        if (tableData.value.length) {
          pullRefreshOpt.finishedText = "没有更多了";
        } else {
          pullRefreshOpt.finishedText = "";
        }
      })
      .catch(() => {
        pullRefreshOpt.error = true;
      });
  };
  const onLoad = async () => {
    if (pullRefreshOpt.refreshing) {
      tableData.value = [];
      pullRefreshOpt.refreshing = false;
    }
    await nextPageData();
    pullRefreshOpt.loading = false;
    if (tableData.value.length >= pageConfig.total) {
      pullRefreshOpt.finished = true;
    } else {
      pageConfig.page++;
    }
  };
  return {
    pullRefreshOpt,
    onLoad,
    onRefresh,
    nextPageData,
    tableData,
    pageConfig,
  };
}
