export default {
    setToken: ({ commit }, log) => {
        commit('setToken', log);
    },
    setCustomer: ({ commit }, log) => {
        commit('setCustomer', log);
    },
    setName: ({ commit }, log) => {
        commit('setName', log);
    }
}